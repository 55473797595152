@media only screen and (max-width: 768px) {
  .header-center-content {
    display: none;
  }
  .langauge-secion-list-li {
    display: none !important;
  }
  .langauge-secion-menu-bar-mobile {
    display: block !important;
  }
  .hero-holder-content {
    padding: 140px 0 50px 0;
    .hero-content-main {
      flex-direction: column;
      .hero-text-box {
        margin-bottom: 50px;
        h1 {
          font-size: 42px;
          margin-bottom: 25px;
        }
        h2 {
          font-size: 23px;
        }
      }
    }
  }
  .footer-contact-section-holder {
    h1 {
      font-size: 20px !important;
    }
    .hero-text-box {
      margin-bottom: 25px !important;
    }
  }
  .products-content-main {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px 15px;
    .products-item {
      .products-item-holder {
        .products-item-text {
          h3 {
            font-size: 15px;
            height: 35px;
          }
        }
      }
    }
  }
  .info-image-text-section-holder {
    .info-image-text-section-content {
      flex-direction: column-reverse;
      .info-image-text-section-text {
        .info-image-text-section-text-holder {
          padding: 0px;
        }
      }
    }
  }
  .gallery-holder-content {
    .gallery-content-main {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .gallery-holder-content {
    margin-bottom: 100px;
  }
  footer {
    .footer-content {
      .footer-content-logo {
        flex-direction: column;
        gap: 10px;
      }

      .footer-content-logo-text {
        font-size: 12px;
      }

      .footer-content-right {
        .footer-content-right-icons {
          gap: 10px;
        }
      }
    }
  }
  .equipment-section {
    .section-title {
      h2 {
        font-size: 40px;
      }
    }
  }
  .info-image-text-section-holder {
    .info-image-text-section-content {
      .info-image-text-section-text {
        h4 {
          font-size: 30px;
          margin-bottom: 40px;
          max-width: 100%;
        }
        p {
          font-size: 22px;
        }
      }
    }
  }
  .product-detail-hero-holder {
    .hero-holder-content {
      .hero-content-main {
        .hero-text-box {
          h1 {
            font-size: 30px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .product-detail-holder-content {
    .product-detail-content-main {
      .product-detail-information {
        flex-direction: column-reverse;
        gap: 80px;

        .product-detail-text-holder {
          .product-detail-size-holder {
            .product-detail-size-items {
              flex-wrap: wrap;
            }
          }
          .product-detail-quantity-holder {
            .product-detail-quantity-content {
              .add-to-cart-button {
                padding: 10px 30px;
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }
  .number-input {
    button {
      padding: 11px 19px;
    }
  }
  .cart-holder {
    width: 100%;
  }
  .cart-content-total-price-holder {
    flex-direction: column;
    .cart-content-total-left-side {
      margin-bottom: 30px;
    }
  }

  .cart-content-table-holder {
    overflow-y: auto;

    .mobile-width-column {
      width: 140px;
    }
  }
  .checkout-content-main {
    flex-direction: column-reverse;
  }
  .checout-button-info-policy {
    margin-bottom: 10px;
    span {
      font-size: 15px;
    }
  }
  .checkout-content-information-items {
    width: 100% !important;
  }
  .checkout-information-list-item-price-grinding {
    flex-direction: column !important;
    gap: 10px;
  }
  .mobile-menu-holder-content {
    .hero-holder-content {
      .hero-content-main {
        .hero-image-box {
          img {
            max-height: 150px !important;
          }
        }
      }
    }
  }
  .section-text-image-holder {
    margin-bottom: 100px;
    .section-text-image-content {
      flex-direction: column-reverse;
      .section-text-image-text {
        .section-text-image-holder-information {
          padding: 0px;
        }
      }
    }
  }
  .section-image-text-holder {
    margin-bottom: 50px;
    .section-image-text-content {
      flex-direction: column;
      .section-image-text-text {
        .section-image-text-holder {
          padding: 0px;
          margin-bottom: 50px;
        }
      }
    }
  }
  .about-info-section-holder {
    margin-bottom: 100px;
    .about-info-section-content {
      .section-title {
        h2 {
          font-size: 35px;
          font-family: MontserratMedium;
          width: 100%;
        }
      }
      .about-info-section-items {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
      }
    }
  }
  .contact-content-main .contact-content-form-holder {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
    margin-bottom: 80px;
  }
  .margin-top-mobile-4 {
    margin-top: 1.5rem !important;
  }

  .intro-holder-main {
    height: 100vh;
    .intro-holder-main-content {
      height: 100%;
      .intro-holder-main-content-text {
        display: flex;
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .intro-holder-main-content-image {
          margin-bottom: 100px;
          img {
            max-width: 200px;
          }
        }
        .intro-holder-main-content-second-image {
          margin-bottom: 50px;
          img {
            max-width: 100%;
          }
        }
        .intro-holder-main-content-title {
          text-align: center;
          h1 {
            font-size: 35px;
            font-family: MontserratBold;
            margin-bottom: 20px;
          }
          p {
            font-family: MontserratRegular;
            margin-bottom: 0px;
          }
        }
      }
    }
    .intro-holder-main-instagram {
      img {
        max-width: 50px;
      }
      margin-top: 30px;
    }
  }
  .mobile-menu-holder {
    .mobile-menu-holder-content-items {
      margin-top: 0px;
      ul {
        li {
          margin-bottom: 15px;
        }
      }
    }
    .mobile-menu-header {
      padding: 32px 20px;
    }
  }
  .cart-holder {
    .cart-holder-content {
      .cart-holder-header {
        padding: 15px 20px;
      }
      .cart-holder-footer {
        padding: 20px 20px 80px 20px;
      }
    }
  }
}
