@font-face {
  font-family: RalewayBold;
  src: url(../assets/fonts/raleway/Raleway-Bold.ttf);
}

@font-face {
  font-family: RalewayRegular;
  src: url(../assets/fonts/raleway/Raleway-Regular.ttf);
}

@font-face {
  font-family: MontserratMedium;
  src: url(../assets/fonts/montserrat/Montserrat-Medium.ttf);
}

@font-face {
  font-family: MontserratSemiBold;
  src: url(../assets/fonts/montserrat/Montserrat-SemiBold.ttf);
}

@font-face {
  font-family: MontserratBold;
  src: url(../assets/fonts/montserrat/Montserrat-Bold.ttf);
}

@font-face {
  font-family: MontserratRegular;
  src: url(../assets/fonts/montserrat/Montserrat-Regular.ttf);
}

@font-face {
  font-family: MontserratMediumItalic;
  src: url(../assets/fonts/montserrat/Montserrat-MediumItalic.ttf);
}

@font-face {
  font-family: MontserratLightItalic;
  src: url(../assets/fonts/montserrat/Montserrat-LightItalic.ttf);
}

body {
  background-color: #f7f6f4;
  font-family: MontserratRegular;
  font-size: 18px;
  color: #262626;
  z-index: 1;
}

header {
  font-size: 16px;
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  &.fixed-menu {
    width: 100%;
    background: #f7f6f4;
    box-shadow: 0 4px 8px -4px grey;
  }

  .header-top-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100px;
  }
  .header-left-content {
    width: 150px;
  }
  .header-center-content {
    ul {
      list-style: none;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 0px;
      padding: 0px;
      gap: 60px;

      li {
        cursor: pointer;

        a {
          text-decoration: none;
          color: #262626;
        }
      }
    }
  }
  .header-right-content {
    .icon-header-image {
      width: 18px;
    }

    ul {
      list-style: none;
      display: flex;
      gap: 30px;
      padding: 0px;
      margin: 0px;

      li {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
      }
    }
    .header-right-content-text {
      margin-left: 5px;
    }
  }
}
footer {
  background-color: #262626;
  color: #fff;

  .footer-content {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: 100px;
    align-items: center;

    .footer-content-logo {
      display: flex;
      gap: 30px;
      font-size: 14px;
      a {
        color: #fff;
      }
      img {
        width: 90px;
      }
    }

    .footer-content-right {
      .footer-content-right-icons {
        display: flex;
        flex-direction: row;
        gap: 20px;
        .footer-icon {
          img {
            width: 18px;
          }
        }
      }
    }
  }
}
.hero-holder-content {
  padding: 220px 0 130px 0;
  .hero-content-main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .hero-text-box {
      max-width: 600px;
      h1 {
        font-family: MontserratMedium;
        font-size: 53px;
        margin-bottom: 100px;
      }
      h2 {
        font-family: MontserratMedium;
        font-size: 30px;
        margin-bottom: 20px;
      }
      h3 {
        text-decoration: underline;
        cursor: pointer;
        font-size: 20px;

        a {
          color: #262626;
        }
      }
    }

    .hero-image-box {
      img {
        max-height: 300px;
      }
    }
  }
}
.home-holder-content {
  .hero-holder-content {
    .hero-image-box {
      img {
        max-height: 270px;
      }
    }
  }
}
.footer-contact-section-holder {
  .hero-holder-content {
    padding-top: 0px;
    .hero-content-main {
      .hero-image-box {
        img {
          max-height: 350px;
        }
      }
    }
  }
}
.gallery-holder-content {
  margin-bottom: 200px;
  .gallery-content-main {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }
}
.header-language-content {
  display: flex;
  gap: 5px;

  .header-lagunage-item {
    &.active {
      font-family: MontserratBold;
    }
  }
}
.products-section-holder {
  padding-bottom: 100px;
}
.product-detail-holder-content {
  .product-detail-hero-holder {
    .hero-holder-content {
      padding-bottom: 0px;
    }
  }
  .product-detail-content-main {
    .product-detail-information {
      display: flex;
      flex-direction: row;
      gap: 80px;
      justify-content: space-between;

      .product-detail-text-holder {
        flex: 1;

        .product-detail-category-status-holder {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 25px;

          .product-detail-category {
            font-family: MontserratMedium;
            font-size: 25px;
          }
          .product-detail-status {
            font-size: 16px;

            span {
              color: #859551;
            }
          }
        }
        .product-detail-short-description {
          h5 {
            font-size: 16px;
          }
        }
        .product-detail-size-holder {
          margin-top: 25px;

          .product-detail-section-title {
            margin-bottom: 10px;
          }
          .product-detail-size-items {
            display: flex;
            justify-content: start;
            gap: 20px;

            .product-detail-size-item {
              min-width: 100px;
              padding: 5px 15px;
              border: 1px solid #a3a5a8;
              background-color: #fff;
              text-align: center;
              cursor: pointer;

              &.active {
                background-color: #861a22;
                color: #fff;
              }
            }
          }
        }
        .product-detail-grinding-holder {
          margin-top: 25px;

          .product-detail-section-title {
            margin-bottom: 10px;
          }
          .product-detail-grinding-items {
            display: flex;
            justify-content: start;
            gap: 20px;

            .product-detail-grinding-item {
              min-width: 100px;
              padding: 5px 15px;
              border: 1px solid #a3a5a8;
              background-color: #fff;
              text-align: center;
              cursor: pointer;

              &.active {
                background-color: #861a22;
                color: #fff;
              }
            }
          }
        }
        .product-detail-price-holder {
          margin-top: 25px;

          .product-detail-price {
            font-size: 30px;
            font-family: MontserratMedium;

            small {
              font-family: MontserratRegular;
              font-size: 18px;
            }
          }
        }
        .product-detail-quantity-holder {
          margin-top: 40px;

          .product-detail-quantity-content {
            border-top: 1px solid #262626;
            padding-top: 20px;
            display: flex;
            justify-content: start;
            gap: 20px;

            .add-to-cart-button {
              background-color: #262626;
              color: #fff;
              border: none;
              font-size: 22px;
              padding: 10px 50px;

              &:disabled {
                background-color: #a3a5a8;
                cursor: not-allowed;
              }
            }
          }
        }
      }
      .product-detail-image-holder {
        flex: 1;

        img {
          max-width: 100%;
        }
      }
    }

    .product-detail-more {
      margin-top: 100px;

      .product-detail-more-list {
        display: flex;
        justify-content: center;
        gap: 30px;

        .prodcut-detail-more-item {
          margin-bottom: 35px;
          cursor: pointer;
          &.active {
            font-family: MontserratSemiBold;
          }
        }
      }
    }
  }
}
.number-input {
  display: inline-flex;
  align-items: center;
  border: 1px solid #262626;
  overflow: hidden;
  background-color: #fff;

  button {
    background-color: #fff; // Svetloplava boja
    border: none;
    padding: 12px 19px;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    transition: background-color 0.2s;

    &:hover {
      background-color: #a3a5a81f; // Tamnija nijansa na hover
    }

    &:active {
      background-color: #a3a5a81f; // Još tamnija nijansa na klik
    }

    &.minus {
      border-right: 1px solid #262626;
    }

    &.plus {
      border-left: 1px solid #262626;
    }

    :disabled {
      background-color: #fff !important;
    }
  }

  .number {
    width: 50px;
    text-align: center;
    border: none;
    font-size: 20px;
    padding: 5px;

    &:focus {
      outline: none;
      border: 1px solid #70a1ff; // Fokus boja
    }
  }
}
input:disabled {
  background-color: #fff;
}

.products-content-main {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px 25px;

  a {
    text-decoration: none;
    color: #262626;
  }

  .products-item {
    cursor: pointer;

    .products-item-holder {
      display: flex;
      flex-direction: column;

      .products-item-text {
        h4 {
          color: #a3a5a8;
          font-family: MontserratMedium;
          font-size: 11px;
          text-transform: uppercase;
          margin-top: 10px;
        }
        h3 {
          font-family: MontserratBold;
          font-size: 16px;
          height: 40px;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        p {
          font-family: MontserratBold;
          margin-bottom: 0px;
          font-size: 14px;
          color: #a3a5a8;
        }
      }
    }
  }
}
.section-title {
  font-family: MontserratMedium;
  font-size: 45px;
}
.related-products-section-holder {
  margin: 100px 0 150px 0;

  .related-products-section-content {
    margin-top: 50px;
  }
}
.catalog-holder-content {
  .products-content-main {
    margin-bottom: 200px;
  }
  .products-catalog-filter-holder {
    margin-bottom: 30px;

    .showing-results {
      font-size: 20px;
    }
  }
}
.product-button-holder {
  margin-top: 30px;
  width: 100%;
  text-align: center;
}
.button-custom-black {
  border: 1px solid #262626;
  background-color: #262626;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  font-family: MontserratMedium;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;

  &:hover {
    background-color: rgba(38, 38, 38, 0.8);
  }
}
.button-custom-silver {
  border: 1px solid #d1cac0;
  background-color: #d1cac0;
  color: #262626;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  font-family: MontserratMedium;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c2b9ac;
  }
}
.info-image-text-section-holder {
  margin-bottom: 120px;
  .info-image-text-section-content {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    gap: 40px;

    .info-image-text-section-image {
      flex: 1;
    }
    .info-image-text-section-text {
      flex: 1;

      .info-image-text-section-text-holder {
        padding: 0 0 0 150px;
      }

      h4 {
        font-size: 35px;
        margin-bottom: 40px;
        max-width: 400px;
        font-family: MontserratMedium;
      }

      p {
        font-size: 27px;
      }
    }
  }
}
.equipment-section {
  .section-title {
    margin-bottom: 50px;

    h2 {
      font-family: MontserratMedium;
      font-size: 53px;
    }
  }
}
.section-text-image-holder {
  margin-bottom: 150px;
  .section-text-image-content {
    display: flex;
    justify-content: center;
    align-items: center;

    .section-text-image-image {
      flex: 1;
    }
    .section-text-image-text {
      flex: 1;

      .section-text-image-holder-information {
        padding: 0px 80px 0px 150px;
      }

      h4 {
        font-size: 30px;
        font-family: MontserratMedium;
        margin-bottom: 50px;
        max-width: 300px;
      }

      p {
        font-size: 18px;
      }
    }
  }
}
.section-image-text-holder {
  margin-bottom: 150px;
  .section-image-text-content {
    display: flex;
    justify-content: center;
    align-items: center;

    .section-image-text-image {
      flex: 1;
    }
    .section-image-text-text {
      flex: 1;

      .section-image-text-holder {
        padding: 0px 150px 0px 80px;
      }

      h4 {
        font-size: 30px;
        font-family: MontserratMedium;
        margin-bottom: 50px;
        max-width: 300px;
      }

      p {
        font-size: 18px;
      }
    }
  }
}
.about-info-section-holder {
  margin-bottom: 170px;
  .about-info-section-content {
    .section-title {
      margin-bottom: 110px;
      h2 {
        font-size: 57px;
        font-family: MontserratMedium;
        max-width: 650px;
      }
    }
    .about-info-section-items {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 140px;

      .about-info-section-item {
        h4 {
          font-size: 22px;
          font-family: MontserratSemiBold;
          margin-top: 20px;
        }
      }
    }
  }
}
.contact-content-main {
  .contact-content-map-holder {
    margin-bottom: 150px;
  }
  .contact-content-form-holder {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    margin-bottom: 80px;

    .contact-content-information-holder {
      margin-top: 30px;
      .contact-information-items {
        display: flex;
        flex-direction: column;

        .contact-information-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
          .contact-information-item-image {
            img {
              width: 30px;
            }
          }
          .contact-information-item-text {
            flex: 1;
            margin-left: 20px;
            display: flex;
            flex-direction: column;

            h4 {
              font-size: 20px;
              margin-bottom: 0px;
              font-family: MontserratSemiBold;
            }
            p {
              font-size: 16px;
              font-family: MontserratMedium;
              margin-bottom: 0px;
            }
          }
        }
      }
      .contact-information-description {
        margin-top: 20px;
        p {
          font-size: 16px;
          font-family: MontserratMedium;
        }
      }
    }
  }
}
.contact-form-container {
  input,
  textarea {
    border-radius: 0px;
    background-color: transparent;
    border-color: #d9d9d9;
    padding: 20px 10px;
  }
}
.contact-form-container {
  margin-top: 30px;
}
.contact-information-social {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 30px;

    li {
      img {
        width: 20px;
      }
    }
  }
}
.cart-holder {
  position: fixed;
  right: -100vh;
  height: 100vh;
  top: 0;
  background: #fff;
  width: 450px;
  transition: right 0.5s ease;
  box-shadow: 0 4px 8px -4px grey;

  &.active {
    right: 0; /* Moves the cart into the viewport */
  }

  .cart-holder-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .cart-holder-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 35px 20px;
      font-size: 20px;
      background-color: #262626;
      color: #fff;
    }
    .cart-holder-header-close {
      cursor: pointer;
    }
    .cart-holder-body {
      flex: 1;
      overflow-y: auto;
      .cart-body-content {
        height: 100%;

        .cart-content-empty {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #262626;
          h4 {
            font-size: 18px;
          }
        }
      }

      .cart-content-items {
        padding: 20px;

        .cart-content-item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          gap: 20px;
          margin-bottom: 25px;

          .cart-content-item-text {
            flex: 1;
            h5 {
              font-family: MontserratBold;
              font-size: 16px;
            }
          }
          .cart-content-item-remove {
            cursor: pointer;
          }

          .cart-content-item-image {
            img {
              width: 100px;
            }
          }
        }
      }
    }
    .cart-holder-footer-total {
      display: flex;
      justify-content: space-between;
      padding: 20px;
      font-size: 20px;

      .cart-holder-footer-total-title {
        font-family: MontserratBold;
      }

      .cart-holder-footer-total-price {
        font-family: MontserratSemiBold;
      }
    }
    .cart-holder-footer {
      background-color: #f7f6f4;
      padding: 20px 20px;

      display: flex;
      justify-content: space-between;
      gap: 20px;
    }
  }
}
.cart-holder-content {
  .cart-hero-holder {
    .hero-holder-content {
      padding-bottom: 50px;
    }
  }
  table {
    thead {
      th {
        background-color: #f7f6f4;
      }
    }
    tbody {
      tr {
        td {
          vertical-align: middle;
          background-color: #f7f6f4;
          border: none;
          font-size: 16px;

          .number-input {
            button {
              padding: 4px 8px;
            }
            input {
              font-size: 16px;
            }
          }

          .image-content-cart-item-name-holder {
            display: flex;
            align-items: center;
            gap: 20px;
            max-width: 450px;
          }

          h5 {
            font-family: MontserratSemiBold;
            font-size: 18px;
          }

          img {
            max-width: 95px;
          }

          &.table-cart-item-subtotal {
            text-align: right;
          }

          &.table-cart-item-remove {
            text-align: right;
            svg {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .cart-content-total-price-holder {
    display: flex;
    justify-content: space-between;
    padding: 35px 0 100px 0;
    border-top: 1px solid #dee2e6;

    .cart-content-total-left-side {
      .cart-content-continue-shopping {
        a {
          color: #262626;
          font-size: 16px;
        }
      }
    }

    .cart-content-total-right-side {
      min-width: 250px;
      .cart-content-total-price-conetnt {
        border-top: 1px solid #dee2e6;
        padding: 20px 0px;
        border-bottom: 1px solid #dee2e6;
      }
      .cart-content-total-title {
        font-size: 30px;
        font-family: MontserratMedium;
        margin-bottom: 30px;
      }

      .cart-content-total-total {
        margin-top: 30px;
        font-family: MontserratMedium;
        font-size: 22px;
      }
    }
  }
}
.overlay-cart-background {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.2s ease-in-out;
  z-index: 2;
}
.checkout-holder-content {
  .checkout-hero-holder {
    .hero-holder-content {
      padding-bottom: 50px;
    }
  }
  .checkout-content-main {
    display: flex;
    gap: 30px;
    margin-bottom: 100px;
    justify-content: space-around;

    .checkout-content-form-holder {
      flex: 1;

      .checkout-content-form {
        background-color: #fff;
        padding: 30px 15px 15px 15px;
        .checkout-title-info {
          margin-bottom: 15px;
          h5 {
            font-family: MontserratSemiBold;
            font-size: 21px;
          }
        }
        .checkout-title-info-section {
          margin-bottom: 15px;
          margin-top: 40px;
          h5 {
            font-family: MontserratSemiBold;
            font-size: 21px;
            margin-bottom: 15px;
          }
        }
      }

      .checkout-button-holder {
        padding: 35px 15px 30px 15px;
      }
    }
    .checkout-content-information-items {
      width: 500px;
    }
  }
}
.MuiFormControlLabel-root {
  label,
  p,
  span {
    font-family: MontserratRegular !important;
  }
  input,
  textarea {
    font-family: MontserratMedium !important;
  }
}
.MuiFormControl-root {
  label,
  p,
  span {
    font-family: MontserratRegular !important;
  }
  input,
  textarea {
    font-family: MontserratMedium !important;
  }
}
.MuiButtonBase-root {
  span {
    font-family: MontserratRegular !important;
  }
}
.MuiSelect-select {
  font-family: MontserratRegular !important;
}

.MuiCollapse-wrapper {
  label,
  p,
  span {
    font-family: MontserratRegular !important;
  }
}
.payment-methods-accordion-detail {
  background-color: #f7f6f4;
  padding: 10px 15px !important;
  .payment-methods-description {
    font-size: 14px;
    font-family: MontserratMedium;
  }
}
.payment-methods-title {
  .payment-methods-title-h5 {
    span {
      font-size: 16px;
    }
  }
  .Mui-expanded {
    margin: 5px 0px !important;
  }
}
.MuiPaper-root {
  border-radius: 0px !important;
}
.terms-content-main {
  margin-bottom: 100px;
  h2 {
    margin-top: 50px;
    font-size: 25px;
    font-family: MontserratBold;
  }
  p {
    b {
      font-family: MontserratSemiBold;
    }
  }
  ul {
    li {
      b {
        font-family: MontserratSemiBold;
      }
    }
  }
}
.checkout-content-main-holder {
  padding: 30px 30px;

  h3 {
    font-size: 23px;
    font-family: MontserratSemiBold;
  }

  .checkout-information-summary {
    margin-top: 20px;
    .checkout-information-summary-item {
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
    }

    .checkout-information-summary-total {
      margin-top: 10px;
      border-top: 1px solid #262626;
      padding-top: 10px;
      display: flex;
      justify-content: space-between;
      font-size: 22px;
      font-family: MontserratBold;
    }
  }

  .checkout-information-list-items {
    margin-top: 30px;

    .checkout-information-list-item {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-bottom: 25px;

      .checkout-information-list-item-image {
        img {
          width: 120px;
        }
      }
      .checkout-information-list-item-text {
        width: 100%;
        h5 {
          font-family: MontserratSemiBold;
          font-size: 16px;
          margin-bottom: 20px;
        }
        .checkout-information-list-item-size {
          font-size: 15px;
        }
        .checkout-information-list-item-price-grinding {
          font-size: 15px;
          display: flex;
          justify-content: space-between;

          .checkout-information-list-item-price {
            font-family: MontserratSemiBold;
          }
        }
      }
    }
  }
}
.no-scroll {
  overflow: hidden;
}
.cart-content-item-price {
  span {
    // font-family: MontserratSemiBold;
  }
}
.mobile-menu-holder {
  position: fixed;
  right: -200vh;
  height: 100vh;
  top: 0;
  background: #f7f6f4;
  width: 100%;
  transition: right 0.5s ease;
  box-shadow: 0 4px 8px -4px grey;

  &.active {
    right: 0; /* Moves the cart into the viewport */
  }

  .mobile-menu-holder-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .mobile-menu-header {
    display: flex;
    justify-content: space-between;
    height: 100px;
    align-items: center;
    padding: 0px 20px;
    .mobile-menu-header-image {
      width: 150px;
    }

    .mobile-menu-holder-content-close {
      cursor: pointer;
      font-size: 25px;
    }
  }
  .header-lagunage-item {
    cursor: pointer;
  }
  .mobile-menu-holder-content-items {
    margin-top: 20px;
    text-align: center;
    ul {
      list-style: none;
      margin: 0;
      padding: 0px 20px;

      a {
        text-decoration: none;
        color: #262626;
        text-transform: uppercase;
        font-family: MontserratMedium;
        font-size: 20px;
      }
      li {
        margin-bottom: 30px;
      }
    }
  }
  .mobile-menu-holder-content-footer {
    padding: 0px 20px;
  }
}
.intro-holder-main {
  height: 100vh;
  .intro-holder-main-content {
    height: 100%;
    .intro-holder-main-content-text {
      display: flex;
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .intro-holder-main-content-image {
        margin-bottom: 100px;
        img {
          max-width: 250px;
        }
      }
      .intro-holder-main-content-second-image {
        margin-bottom: 50px;
        img {
          max-width: 500px;
        }
      }
      .intro-holder-main-content-title {
        text-align: center;
        h1 {
          font-size: 35px;
          font-family: MontserratBold;
          margin-bottom: 20px;
        }
        p {
          font-family: MontserratRegular;
          margin-bottom: 0px;
        }
      }
    }
  }
  .intro-holder-main-instagram {
    img {
      max-width: 50px;
    }
    margin-top: 30px;
  }
}
